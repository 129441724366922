<template lang="">
  <BModal
    id="modal-package-coupon"
    size="md"
    centered
    header-bg-variant="light-info"
    :title="$t('packageConfig.coupon.createCoupon')"
    :ok-title="$t('packageConfig.coupon.create')"
    :cancel-title="$t('close')"
    cancel-variant="flat-dark"
    :ok-disabled="okDisabled"
    no-close-on-backdrop
    no-close-on-esc
    @show="showHandle"
    @ok="okHandle"
  >
    <IAmOverlay :loading="loading">
      <validation-observer ref="refFormObserver">
        <BForm>
          <div
            style="min-height: 200px"
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <BCol
              cols="12"
              class="d-flex-center justify-content-end px-0"
            >
              <BFormCheckbox
                v-model="isCheckPercentage"
                class="mb-50"
              >
                Giảm giá theo %
              </BFormCheckbox>
            </BCol>

            <BCol
              v-if="isCheckPercentage"
              cols="12"
              class="px-0"
            >
              <validation-provider
                #default="{ errors }"
                :rules="`${isCheckPercentage ? 'required|maxValue:100|min_value:0' : ''}`"
                :name="$t('packageConfig.columns.percentage')"
              >
                <b-form-group
                  label-class="h6"
                  label-for="percentage"
                >
                  <template #label>
                    {{ $t('packageConfig.columns.percentage') }}
                    <span
                      v-if="isCheckPercentage"
                      class="text-danger"
                    >(*)</span>
                  </template>
                  <b-form-input
                    :value="percentage"
                    :name="$t('packageConfig.columns.percentage')"
                    class="d-none"
                  />
                  <b-input-group class="input-group-merge">
                    <IAmInputMoney
                      id="percentage"
                      :value-money.sync="percentage"
                      class="flex-grow-1 unset-border-right"
                      style="min-width: 150px;"
                      :state="errors[0] ? false : null"
                      :name="$t('packageConfig.columns.percentage')"
                      :placeholder="$t('packageConfig.columns.percentage')"
                    />
                    <b-input-group-append
                      is-text
                      :class="{ 'border-state': errors[0] }"
                    >
                      <feather-icon icon="PercentIcon" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </BCol>

            <BCol
              cols="12"
              class="px-0"
            >
              <validation-provider
                #default="{ errors }"
                :rules="`${isCheckPercentage ? '' : 'required|min_value:0'}`"
                :name="$t('packageConfig.columns.amountMax')"
              >
                <b-form-group
                  label-class="h6"
                  label-for="amountMax"
                >
                  <template #label>
                    {{ isCheckPercentage ? 'Giá trị tối đa': $t('packageConfig.columns.amountMax') }}
                    <span
                      v-if="!isCheckPercentage"
                      class="text-danger"
                    >(*)</span>
                  </template>
                  <b-form-input
                    :value="amountMax"
                    :name="$t('packageConfig.columns.amountMax')"
                    class="d-none"
                  />
                  <b-input-group class="input-group-merge">
                    <IAmInputMoney
                      id="amountMax"
                      :value-money.sync="amountMax"
                      class="flex-grow-1 unset-border-right"
                      style="min-width: 150px;"
                      :state="errors[0] ? false : null"
                      :name="$t('packageConfig.columns.amountMax')"
                      :placeholder="isCheckPercentage ? 'Giá trị tối đa': $t('packageConfig.columns.amountMax')"
                    />
                    <b-input-group-append
                      is-text
                      :class="{ 'border-state': errors[0] }"
                    >
                      VND
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </BCol>

            <!-- <BCol
              cols="12"
              class="px-0"
            >
              <b-form-group
                label-class="h6"
                label-for="amountMax"
                :label="isCheckPercentage ? 'Giá trị tối đa': $t('packageConfig.columns.amountMax')"
              >
                <b-input-group
                  class="input-group-merge"
                >
                  <IAmInputMoney
                    id="amountMax"
                    :value-money.sync="amountMax"
                    class="flex-grow-1 unset-border-right"
                    style="min-width: 150px;"
                    :placeholder="isCheckPercentage ? 'Giá trị tối đa': $t('packageConfig.columns.amountMax')"
                  />
                  <b-input-group-append
                    is-text
                  >
                    VND
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </BCol> -->
          </div>
        </BForm>
      </validation-observer>
    </IAmOverlay>
  </BModal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BCol, BFormGroup, BInputGroupAppend, BInputGroup, BFormCheckbox, BForm, BFormInput,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { formatCurrency } from '@core/utils/filter'

import usePackageCouponHandle from './usePackageCouponHandle'

export default {
  components: {
    BModal,
    BCol,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormInput,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {

  },
  setup(_, { root, emit }) {
    const okDisabled = ref(false)
    const isCheckPercentage = ref(false)
    const amountMax = ref()
    const percentage = ref()
    const invalidPercentage = ref()
    const invalidAmountMax = ref()

    const { createPackageCoupon, loading } = usePackageCouponHandle()
    function reset() {
      amountMax.value = null
      percentage.value = null
    }

    function showHandle() {
      reset()
      isCheckPercentage.value = false
      okDisabled.value = false
    }

    async function okHandle(e) {
      e.preventDefault()
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            const payload = {
              amountMax: amountMax.value || undefined,
              percentage: percentage.value || undefined,
            }
            await createPackageCoupon(payload)
            emit('refetch-data')

            root.$bvModal.hide('modal-package-coupon')
          }
        })
    }
    return {
      showHandle,
      okHandle,
      okDisabled,

      formatCurrency,
      isCheckPercentage,
      percentage,
      amountMax,
      loading,
      invalidAmountMax,
      invalidPercentage,
    }
  },
}
</script>

<style lang="scss" scoped>
.unset-border-right ::v-deep{
  input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.border-state ::v-deep{
  .input-group-text {
    border-color: red !important;
  }
}
</style>
